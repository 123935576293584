// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import {initBootstrap} from "./bootstrap.js";

import 'bs5-lightbox';

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: false,
});

// Your app code
//console.log(`Hello ${process.env.HELLO}`);






